import React from 'react'

import styles from './Skills.module.css'

const Skills = () => {
  return (
    <div id='resume-page' class='page'>
      <div class='page-title'>
        <h1>Skills</h1>
      </div>
      <div className={styles.row}>
        <Circle name='Web Development' />
        <Circle name='Typescript' />
        <Circle name='React / Redux' />
        <Circle name='Next js' />
        <Circle name='WEB3' />
        <Circle name='Solana' />
        <Circle name='macOS Development' />
        <Circle name='Firebase' />
        <Circle name='AWS' />
        <Circle name='Node' />
        <Circle name='Javascript' />
        <Circle name='React Native' />
        <Circle name='Express' />
        <Circle name='HTML CSS' />
        <Circle name='Mongodb' />
        <Circle name='SQL' />
        <Circle name='iOS Development' />
        <Circle name='Swift' />
        <Circle name='Objective C' />
        <Circle name='Java' />
      </div>
    </div>
  )
}

const Circle = ({ name }) => {
  return <div className={styles['circle-text']}>{name}</div>
}

export default Skills
