const constant = {
  names: {
    skills: 'Skills',
    education: 'Education',
    work: 'Work',
    contact: 'Contact'
  }
};

export default constant;
