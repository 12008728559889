import React, { useState } from 'react'
import './Contact.css'
import * as Sentry from '@sentry/react'
import { useFormFields } from '../../utilities/formHooks'

import bitmoji from '../../bitmoji5.png'

const Contact = () => {
  const [submit, setSubmit] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  const showForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          Sentry.withScope(function (scope) {
            Sentry.setContext('formFields', fields)
            Sentry.captureMessage(
              `Portfolio Contact: Contacted by ${fields.email}`
            )
          })

          setSubmit(true)
        }}
      >
        <div className='inputBox'>
          <input
            type='text'
            name='name'
            required=''
            // minLength='5'
            placeholder='James Bond'
            value={fields.name}
            onChange={handleFieldChange}
          />
          <label>What did your parents name you?</label>
        </div>
        <div className='inputBox'>
          <input
            type='text'
            name='email'
            required=''
            placeholder='youareawesome@justkidding.com'
            value={fields.email}
            onChange={handleFieldChange}
          />
          <label>How can I get back to you?</label>
        </div>
        <div className='inputBox'>
          <input
            type='text'
            name='subject'
            required=''
            placeholder='Typescript 🔥 Javascript 👎🏻'
            value={fields.subject}
            onChange={handleFieldChange}
          />
          <label>Subject</label>
        </div>
        <div className='inputBox'>
          <input
            type='text'
            name='message'
            required=''
            placeholder='You have my attention. Be descriptive!'
            value={fields.message}
            onChange={handleFieldChange}
          />
          <label>Message</label>
        </div>
        <input type='submit' name='' value='Submit' />
        <p style={{ padding: '8px' }}>or</p>
        <a
          href='https://booking.appointy.com/nihalanisumit'
          target='_blank'
          style={{ color: '#eee' }}
        >
          <p>Book an appointment</p>
        </a>
      </form>
    )
  }

  const showMessage = () => {
    return (
      <div>
        <img src={bitmoji} alt='My bitmoji' className='bitImage' />
        <p className='Thankyou-text'>
          {' '}
          Thank you for contacting me. I will get back to you as soon as
          possible
        </p>
      </div>
    )
  }

  return (
    <div id='resume-page' className='page'>
      <div className='page-title'>
        <h1>Contact Me</h1>
      </div>
      <div className='container1234'>
        <div className='box'>{submit ? showMessage() : showForm()}</div>
      </div>
    </div>
  )
}

export default Contact
