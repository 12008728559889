import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

import Hexagon from './components/hexagon'
import constants from './Constants'
import Home from './components/main/Home'
import Skills from './components/main/Skills'
import Education from './components/main/Education'
import Work from './components/main/Work'
import Contact from './components/main/Contact'
import resume from './resume.pdf'
import { FaDownload } from 'react-icons/fa'
import './Layout.css'
import './App.css'

class Layout extends Component {
  state = {
    body: 'home',
  }
  openUrl = (url) => {
    window.open(url, '_blank')
  }

  renderMain = () => {
    if (this.state.body === 'home') {
      return <Home />
    }
    if (this.state.body === 'skills') {
      return <Skills />
    }
    if (this.state.body === 'education') {
      return <Education />
    }
    if (this.state.body === 'contact') {
      return <Contact />
    }
    if (this.state.body === 'work') {
      return <Work />
    }
  }

  changeMain = (page) => {
    this.setState({ body: page })
  }

  render() {
    return (
      <div id='page-wrapper'>
        <header id='header' role='banner'>
          <ul className='header-menu'>
            <li
              className='skills hexagon'
              onClick={() => this.changeMain('skills')}
            >
              <Hexagon
                name={constants.names.skills}
                iconStyle='fa icon-picture'
              />
            </li>
            <li
              className='education hexagon'
              onClick={() => this.changeMain('education')}
            >
              <Hexagon
                name={constants.names.education}
                iconStyle='fa icon-vcard'
              />
            </li>
            <li
              className='userpic hexagon'
              onClick={() => this.changeMain('home')}
            >
              <Hexagon isUserPic={true} />
            </li>
            <li
              className='work hexagon'
              onClick={() => this.changeMain('work')}
            >
              <Hexagon name={constants.names.work} iconStyle='icon-chat' />
            </li>
            <li
              className='contact hexagon'
              onClick={() => this.changeMain('contact')}
            >
              <Hexagon name={constants.names.contact} iconStyle='icon-mail' />
            </li>
          </ul>
        </header>

        <section id='main'>
          <div className='wrapper'>{this.renderMain()}</div>
        </section>

        <footer id='footer' role='contentinfo'>
          <ul>
            <li
              className='medium hexagon'
              onClick={this.openUrl.bind(
                this,
                'https://linktr.ee/nihalanisumit'
              )}
            >
              <Hexagon iconName='linktree' />
            </li>
            <li
              className='github hexagon'
              onClick={this.openUrl.bind(
                this,
                'https://github.com/nihalanisumit'
              )}
            >
              <Hexagon iconName='github' />
            </li>
            <li className='copyright'>
              <ReactTooltip place='bottom' effect='solid' />
              <a
                href={resume}
                download='Sumit Nihalani resume'
                style={{ color: 'white' }}
                data-tip='Download Resume'
              >
                <FaDownload className='downloadStyle' />
              </a>
            </li>
            <li
              className='linkedin hexagon'
              onClick={this.openUrl.bind(
                this,
                'https://www.linkedin.com/in/nihalanisumit/'
              )}
            >
              <Hexagon iconName='linkedin' />
            </li>
            <li
              className='twitter hexagon'
              onClick={this.openUrl.bind(
                this,
                'https://twitter.com/nihalanisumit'
              )}
            >
              <Hexagon iconName='twitter' />
            </li>
            <li className='download hexagon'>
              <a href={resume} download='Sumit Nihalani resume'>
                <Hexagon iconName='download' />{' '}
              </a>
            </li>
          </ul>
        </footer>
      </div>
    )
  }
}

export default Layout
