import React from 'react'
import logo from '../dp1.jpg'
import '../Layout.css'

import {
  FaLinkedinIn,
  FaMediumM,
  FaTwitter,
  FaGithub,
  FaDownload,
} from 'react-icons/fa'

import { SiLinktree } from 'react-icons/si'

const Hexagon = ({ name, iconStyle, isUserPic, iconName }) => {
  const getIcons = (iconName) => {
    if (iconName === 'linkedin') {
      return <FaLinkedinIn className={'iconStyleInHexagon'} />
    }
    if (iconName === 'linktree') {
      return <SiLinktree className={'iconStyleInHexagon'} />
    }
    if (iconName === 'github') {
      return <FaGithub className={'iconStyleInHexagon'} />
    }
    if (iconName === 'twitter') {
      return <FaTwitter className={'iconStyleInHexagon'} />
    }
    if (iconName === 'download') {
      return <FaDownload className={'iconStyleInHexagon'} />
    }
  }
  let renderImage = null

  if (isUserPic === true) {
    renderImage = (
      <a href='#' className='active'>
        <img src={logo} alt='' className='userPicImageStyle' />
      </a>
    )
  }
  return (
    <div className='hexagon-in1'>
      <div className='hexagon-in2'>
        {isUserPic ? (
          renderImage
        ) : (
          <div className='inner'>
            {name ? (
              <div className='test upper'>
                <div> {name} </div>{' '}
              </div>
            ) : (
              <div className='test lower'>{getIcons(iconName)}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Hexagon
