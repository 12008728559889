import React from 'react'
import * as Sentry from '@sentry/react'
import './App.css'

import Layout from './Layout'

function App() {
  return (
    <div className='App'>
      <Sentry.ErrorBoundary fallback={'An error has occured'}>
        <Layout />
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default App
