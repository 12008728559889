import React from 'react'
import './work.scss'

import { FaAngleDown } from 'react-icons/fa'

const data = [
  {
    title: 'cryptoisreal - web3 NFT project',
    link: 'http://cryptoisreal.com/',
    stack:
      'Next, Nodejs, graphql, socket.io, Solana, Metaplex, Candymachine, Airtable, dynamodb',
    paragraph: `A web3 Solana app where we auctioned our own billboard NFTs. NFTs were put up using metaplex and could be auctioned on our web app. On-Chain auction data was pulled using quicknode’s RPC node. 
    As one of the two developers, I was responsible for planning and building this product from the scratch and selecting the tech stack required for this. The frontend was built using nextJS and chakra-UI, the backend had a few REST APIs and graphql APIs, for database we used dynamodb to store auction data on our centralized server whereas all the NFT metadata and billboard cost, agreements, and other data were stored in Airtable.
    `,
  },
  {
    title: 'marketcap.guide',
    link: 'http://marketcap.guide/',
    stack: 'Next, Nodejs, AWS, dynamodb, serverless functions',
    paragraph: `A cryptocurrency ticker just like coinmarketcap but with an aim to present things in a much simpler manner. 
    Key features include simple price charts for all coins, my-coins list after a simple sign-up using mobile OTP, a custom in-house built top tweets algorithm, and Reddit integration to fetch recent posts based on duration and a simple bitcoin guide to educate users about it.
    After a year of launch, we have a DAU count of 1000 and more than 500+ signed-up users.
    `,
  },
  {
    title: 'Remotion - web app',
    link: 'http://app.remotion.com/',
    stack: 'React, Redux, RxJS, Firebase, Nodejs',
    paragraph:
      'Built and owned the ​React-Redux web app​ and server-side work from scratch for authentication, managing teams, invites, and users for Remotion macOS Client. Moving authentication to the web from macOS client made integration with google calendar and slack really simple.',
  },
  {
    title: 'Remotion - macOS Client',
    link: 'https://www.remotion.com/',
    stack:
      'Xcode, Swift, ReSwift, RxSwift, Firebase, Cloud Functions, Pub/Sub, Agora',
    paragraph:
      'Built the native macOS client that helps remote teams stay connected and have more synchronous non-scheduled meetings by putting the team on their desktop and allowing easy video calls and screen sharing.',
  },
  {
    title: 'ME MDM iOS App',
    link: 'https://apps.apple.com/in/app/manageengine-mdm/id720111835',
    stack: 'Xcode, Swift, Objective-c, CoreData',
    paragraph:
      "Built this iOS application from scratch for Zoho's Manage Engine Mobile Device Management (MDM). Contains feature like continuous tracking of location, geo-fencing, document downloading and viewing, screen sharing using replay kit, app extensions and multi-language support.",
  },
  {
    title: 'ME MDM MAC AGENT',
    stack: 'Xcode, Swift, Realm, Packages',
    paragraph:
      "Built this macOS application from scratch for Zoho's Manage Engine Mobile Device Management (MDM). Contains feature like continuous tracking of location, geo-fencing, launchAgents.",
  },
  {
    title: 'Desktop Central MDM Enrollment',
    link: 'https://www.manageengine.com/products/desktop-central/',
    stack: 'Xcode, Objective-c',
    paragraph:
      "Built a command line tool for Zoho's Manage Engine Desktop Central mac agent which enrolls a macbook directly into Manage Engine's MDM by downloading MDM profile from the server.",
  },
  {
    title: 'Lumen',
    link: 'http://lumen-production.netlify.com/',
    stack: 'React, Node, Express, Mongo',
    paragraph:
      'Developed a full stack, dark themed blogging website which supports markdown, drafts and private posts.',
  },
  {
    title: 'Ranking news based on Social Media',
    stack: 'Java, SQL, Graph clustering, JSP, CSS',
    paragraph:
      'As a part of final semester project, developed a framework to rank news and tweets based on various social media factors.',
  },
  {
    title: 'SRM EZY',
    link: 'https://play.google.com/store/apps/details?id=com.srmezy&hl=en_IN',
    stack: 'React native, Node.js, Express',
    paragraph:
      'Developed Application for SRM university college students to check their academic details like marks, attendance and timetable. Have more than ten thousand downloads and 4.3 star rating.',
  },
  {
    title: 'Snake game',
    stack: 'React, Node, Express, Mongo',
    paragraph: 'Developed a snake game and deployed it in netlify.',
  },
  {
    title: 'Poolio',
    stack: 'Android Studio, Java, PHP, SQL',
    paragraph:
      'Developed Android Application and backend for Poolio, a start up which allows people to share their ride or book a shared ride.',
  },
  {
    title: 'Zipy Ride',
    link: 'https://zipy-rides.en.aptoide.com/',
    stack: 'Android Studio, Java, PHP, SQL, XML',
    paragraph:
      'Developed Android Application and backend for Zipy Rides for renting two wheelers to students, start-up got funded because of the mobile application.',
  },
]

const jobData = [
  {
    title: 'Techguides - Full Stack (MERN) and web3 dev',
    stack: 'since November 2020',
    paragraph:
      'Built two web app and one web3 app in my time here. Managed a small team of 5 devs as a tech lead and a project manager. Handled the entire solana based web3 project - cryptoisreal - from frontend, to backend, to communicating with the bloackchain to analytics to marketing.',
  },
  {
    title: 'Alloy - Full Stack (MERN) Contractor',
    link: 'https://www.runalloy.com/',
    stack: '1 month',
    paragraph:
      'During the one month contract, cleaned up their react project, converted few class components to functional components, added client side validation in all the APIs, migrated Ant Design from verision 3 to version 4. Cleaned up their backend project and made it more typescripty.',
  },
  {
    title: 'Remotion - Full Stack (MERN) and macOS Developer',
    link: 'https://www.remotion.com/',
    stack: '1 year',
    paragraph:
      'Worked as a remote full stack developer for Remotion where I helped them build their macOS client, web app, server, custom analytics etc. Talked to customers, took few sales call and also helped Remotion secure their Series-A funding from G​reylock.​',
  },
  {
    title: 'Zoho Corp. - iOS developer',
    link: 'https://www.zohocorp.com/',
    stack: '1 years 6 months',
    paragraph:
      'Worked as an memeber of technical staff for 1.5 years at Zoho Corporation, Chennai. During this time I worked on iOS platform, macOS and also wrote scripts in objective-c. Rolled out many updates for their iOS and macOS apps.',
  },
  {
    title: 'Zipy Rides - Android Developer',
    stack: '3 months',
    paragraph:
      'Interned as an Full stack android developer at Zipy rides, Chennai - A two wheeler rental service. The startup got funded because of the android app.',
  },
]

const Work = () => {
  return (
    <div id='resume-page' className='page'>
      <div className='page-title'>
        <h1>Projects</h1>
      </div>
      <div {...{ className: 'wrapper_acc' }}>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>

      <div className='page-title'>
        <h1>Experience</h1>
      </div>
      <div {...{ className: 'wrapper_acc last' }}>
        <ul {...{ className: 'accordion-list' }}>
          {jobData.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

class AccordionItem extends React.Component {
  state = {
    opened: false,
  }

  render() {
    const {
      props: { paragraph, title, stack, link },
      state: { opened },
    } = this

    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => {
            this.setState({ opened: !opened })
          },
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <div {...{ className: 'accordion-item__linewrapper' }}>
            <h3 {...{ className: 'accordion-item__title' }}>
              {link ? (
                <a href={link} target='_blank' className='custom-link'>
                  {title}
                </a>
              ) : (
                title
              )}
            </h3>
            <h4 {...{ className: 'accordion-item__subtitle' }}>{stack}</h4>
          </div>
          {/* <span {...{ className: 'accordion-item__icon' }} /> */}
          <span>
            {' '}
            <FaAngleDown className='accordion-item__icon' />{' '}
          </span>
        </div>
        <div {...{ className: 'accordion-item__inner' }}>
          <div {...{ className: 'accordion-item__content' }}>
            <p {...{ className: 'accordion-item__paragraph' }}>{paragraph}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Work
