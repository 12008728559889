import React from 'react'
import styles from './Education.module.css'

const educationData = [
  {
    year: 'March 2012',
    text:
      'Graduated class X CBSE board examinations from Seedling Modern High  School, Jaipur with 8.8 / 10 ',
  },
  {
    year: 'March 2014',
    text:
      'Graduated class XII CBSE board examinations from Step by Step High School, Jaipur with 93%',
  },
  {
    year: 'June 2014 - May 2018',
    text:
      "Completed my Bachelor's of Techlology in Computer Science field from SRM Univeristy, Chennai with 9.0 / 10 CGPA",
  },
  {
    year: 'July 2019 - Oct 2019',
    text:
      '3 months career accelerator program where I enhanced my web development skills and got training to work remotely while contributing to open source. ',
  },
]

const Education = () => {
  return (
    <div id='resume-page' className='page'>
      <div className='page-title'>
        <h1>Education</h1>
      </div>
      <div className={styles['timeline']}>
        {educationData.map((data) => (
          <TimeFrame year={data.year} text={data.text} />
        ))}
      </div>
    </div>
  )
}

const TimeFrame = ({ year, text }) => {
  return (
    <div className={[styles.container, styles.right].join(' ')}>
      <div className={styles['content']}>
        <h2 className={styles.year}>{year}</h2>
        <p className={styles.educationText}>{text}</p>
      </div>
    </div>
  )
}

export default Education
