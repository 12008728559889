import React from 'react'
import { MdEmail } from 'react-icons/md'
import { SiLinktree } from 'react-icons/si'
import bitmoji from '../../bitmoji1.png'

const Home = (props) => {
  const renderButtons = () => {
    return (
      <div className='phone'>
        <button
          style={{
            margin: '10px',
            backgroundColor: 'rgba(52, 52, 52, 0.8)',
            border: '0px',
          }}
          onClick={() => {}}
        >
          <a href='mailto:nihalanisumit@gmail.com'>
            <MdEmail
              style={{
                height: '40px',
                width: '40px',
                color: 'white',
                paddingTop: '14px',
              }}
            />
          </a>
          <a href='https://linktr.ee/nihalanisumit' target='_blank'>
            <SiLinktree
              style={{
                height: '36px',
                width: '36px',
                color: 'white',
                paddingTop: '14px',
                paddingLeft: '12px',
              }}
            />
          </a>
        </button>
      </div>
    )
  }
  return (
    <div id='home-page' className='page'>
      <div className='job-title'>Full Stack JS Web &amp; Web3 Developer</div>

      <h1>Sumit Nihalani </h1>
      <div className='home-flexbox'>
        <div className='bitmoji'>
          <img src={bitmoji} alt='My bitmoji' />
        </div>

        <p>
          Passionate about programming and development. Figuring out life by
          solving one bug at a time.
        </p>
      </div>

      {renderButtons()}
    </div>
  )
}

export default Home
